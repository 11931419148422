import React, { useEffect, useRef, useState } from "react";
import "./chatbotheader.scss";
import BotIcon from "../../../assets/icons/bot.svg";
import { useDispatch, useSelector } from "react-redux";
import { hexToRgb, newShade } from "../../../common/validation";
import MenuIcon from "../../../assets/icons/3-menu-icon.png";
import { clearChat } from "../../../store/slices/projectSlice";
import { useLocation, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import useOnClickOutside from "../../../common/Hooks/useOnClickOutside";
import { getUser } from "../../../helpers/utils/auth.util";
export default function Chatbotheader({
  BgColor,
  tempUserData,
  setChatWithBot,
}) {
  const { selectedProject } = useSelector((state) => state.project);
  const user = getUser();
  const menuref = useRef();
  const [boyIcon, setBotIcon] = useState();
  const [isMenu, setIsMenu] = useState(false);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  useOnClickOutside(menuref, () => setIsMenu(false));
  useEffect(() => {
    const BotImage =
      selectedProject?.bot_image instanceof Blob ||
      selectedProject?.bot_image instanceof File
        ? URL.createObjectURL(selectedProject?.bot_image)
        : typeof selectedProject?.bot_image == "string"
        ? selectedProject?.bot_image
        : BotIcon;
    setBotIcon(BotImage);
  }, [selectedProject?.bot_image]);
  const handleClearChat = () => {
    let body = {
      route: pathname?.includes("/chatbot-settings")
        ? "chat/clear-chat"
        : "chat/clear-pub-chat",
      payload: {
        project_id: pathname?.includes("/chatbot-settings")
          ? selectedProject?.id
          : selectedProject?.project_id,
        session_id: pathname?.includes("/chatbot-settings")
          ? user?.session_id
          : tempUserData,
      },
    };
    dispatch(clearChat(body))
      .unwrap()
      .then((res) => {
        if (!res?.data?.success) {
          toast.error(res?.data?.message);
          return;
        }
        toast.success(res?.data?.message);
        setChatWithBot([]);
      });
  };

  return (
    <>
      <div
        className="chat-bot-header"
        style={{
          background: `linear-gradient(180deg, ${BgColor} 0%, ${selectedProject?.base_color} 100%)`,
        }}
      >
        <div className="header-design">
          <img src={boyIcon} alt="BotIcon" />
          <div>
            <p style={{ color: selectedProject?.text_color }}>
              {selectedProject?.bot_name}
            </p>
            <span style={{ color: selectedProject?.text_color }}>Online</span>
          </div>
        </div>
        <div className="text">
          <p style={{ color: selectedProject?.text_color }}>
            {selectedProject?.project_moto}
          </p>
        </div>

        <div className="menu-icon" ref={menuref}>
          <div onClick={() => setIsMenu(!isMenu)}>
            <img src={MenuIcon} />
          </div>
          {isMenu && (
            <div className="menu-item" onClick={() => handleClearChat()}>
              <span>Clear Chat</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
