import React from "react";
import "./settingstab.scss";
import {  useNavigate, useParams } from "react-router-dom";
export default function Settingstab() {
  const navigate = useNavigate();
  const {route}=useParams()

  return (
    <div className="container">
      <div className="setting-tab">
        <a
          onClick={() => navigate("/settings/account")}
          className={route.includes("account") && "active"}
        >
          Account
        </a>
        <a
          onClick={() => navigate("/settings/subscription")}
          className={route.includes("subscription") && "active"}
        >
          Subscription
        </a>
      </div>
    </div>
  );
}
