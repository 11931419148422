import React, { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import "./cropper.css";
import Closeicon from "../../components/modal/common/closeicon";


const ImageCropper = ({
  onCropImage,
  inputImg,
  imgName,
  closeModal,
  ratio,
  OriginalImage,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [cropOptionDropdown, setcropOptionDropdown] = useState(false);
  const [aspect, setAspect] = useState(1 / 1);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(inputImg, croppedAreaPixels);
      // onCropImage(
      //   new File([croppedImage], imgName, {
      //     type: "image/png",
      //     lastModified: new Date().getTime()
      //   })
      // );
      onCropImage(croppedImage);
    } catch (e) {
    
    }
    // eslint-disable-next-line
  }, [croppedAreaPixels]);
  return (
    /* need to have a parent with `position: relative` 
    to prevent cropper taking up whole page */
    <>
      <div className="modal-header-croper">
          <p>Crop Img</p>
          <Closeicon onClick={()=>closeModal()}/>
        </div>
      <div className="">
        {/* <div className="cropper-back-arrow-alignment" onClick={closeModal}>
          <img loading="lazy" src={RightArrow} alt="RightArrow" />
        </div> */}
      




        {/* <div className="cropper-heading">
          <h6>Crop Img</h6>
        </div> */}
      
      </div>
      <div className="cropper">
        <Cropper
          minZoom={1}
          image={inputImg}
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          // restrictPosition={false}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          // cropSize={inputSize}
          style={{
            containerStyle: {
              width: 500,
              height: 500,
              position: "relative",
              // display:"flex",
              // alignItems:"center"
            },
          }}
        />
        <div className="cropper-modal-bootom-details">
          <div style={{display:"flex",alignItems:'center',justifyContent:'center',gap:'10px'}}>
          <div
            className="cropper-option-box-contant"
            onClick={() => setcropOptionDropdown(!cropOptionDropdown)}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 3H21V7"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15 9L21 3"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7 21H3V17"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9 15L3 21"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.02 2.20001C13.37 2.07001 12.69 2 12 2C6.48 2 2 6.48 2 12C2 12.69 2.07001 13.36 2.20001 14"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.97998 21.8C10.63 21.93 11.31 22 12 22C17.52 22 22 17.52 22 12C22 11.32 21.93 10.65 21.8 10"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            {cropOptionDropdown && (
              <div className="cropper-option-dropdown-box-alignment">
                <div
                  className="cropper-option-name-alignment"
                  onClick={() => {
                    OriginalImage(imgName);
                    closeModal();
                  }}
                >
                  <p>Original</p>
                  <div className="cropper-option-img-alignment">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                        stroke="#ffffff"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z"
                        stroke="#ffffff"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.66992 18.95L7.59992 15.64C8.38992 15.11 9.52992 15.17 10.2399 15.78L10.5699 16.07C11.3499 16.74 12.6099 16.74 13.3899 16.07L17.5499 12.5C18.3299 11.83 19.5899 11.83 20.3699 12.5L21.9999 13.9"
                        stroke="#ffffff"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="cropper-option-name-alignment"
                  onClick={() => setAspect(1 / 1)}
                >
                  <p>1.1</p>

                  <div className="cropper-option-img-alignment">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.3 21H14.7C19.2 21 21 19.2 21 14.7V9.3C21 4.8 19.2 3 14.7 3H9.3C4.8 3 3 4.8 3 9.3V14.7C3 19.2 4.8 21 9.3 21Z"
                        stroke="#ffffff "
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="cropper-option-name-alignment"
                  onClick={() => setAspect(4 / 5)}
                >
                  <p>4.5</p>
                  <div className="cropper-option-img-alignment">
                    <svg
                      width="18"
                      height="32"
                      viewBox="0 0 18 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 6V26C17 30 16 31 12 31H6C2 31 1 30 1 26V6C1 2 2 1 6 1H12C16 1 17 2 17 6Z"
                        stroke="#ffffff"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="cropper-option-name-alignment"
                  onClick={() => setAspect(16 / 9)}
                >
                  <p>16.9</p>

                  <div className="cropper-option-img-alignment">
                    <svg
                      width="32"
                      height="18"
                      viewBox="0 0 32 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M26 17L6 17C2 17 0.999999 16 1 12L1 6C1 2 2 0.999999 6 0.999999L26 1C30 1 31 2 31 6L31 12C31 16 30 17 26 17Z"
                        stroke="#ffffff"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="cropper-option-box-contant">
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 21L16.65 16.65M11 8V14M8 11H14M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          {/* <button onClick={closeModal} className="btn-cancel">
            Cancel
          </button> */}
          {/* <div class="slidecontainer"> */}
          <input
            type="range"
            id="range"
            defaultValue={zoom}
            value={zoom}
            // min={ratio ? 1 : 1}
            min={1}
            max={3.2}
            step={0.1}
            onChange={(e) => setZoom(e.target.value)}
          />
</div>
          {/* </div> */}
          {/* <button
            onClick={() => {
              showCroppedImage();
              closeModal();
            }}
            className="btn-save"
          >
            Save
          </button> */}
            <div className="cropper-save-button" style={{float:'right'}}>
          <button
            onClick={() => {
              showCroppedImage();
              closeModal();
            }}
          >
            Save
          </button>
        </div>
        </div>
      </div>
    </>
  );
};

export default ImageCropper;
