import React, { useEffect, useState } from 'react'
import ReactECharts from "echarts-for-react";
import './usage.scss';
import { useSelector } from 'react-redux';
export default function Usage() {
  const {stats}=useSelector((state)=>state.project)
  const [option, setOption] = useState({});
  const chartColor = "#d1d5db";
  const fontFamily = '"Poppins", sans-serif';
  useEffect(() => {
    if (stats?.usage_chart) {
      setOption({
        title: {
          text: "Usage",
          textStyle: {
            color: chartColor,
            fontSize: 20,
            fontFamily: fontFamily,
          },
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: Object.keys(stats?.usage_chart?.plot),
          textStyle: { color: chartColor, fontFamily: fontFamily },
        },
        style: {
          color: "white",
        },
        grid: {
          left: "4%",
          right: "6%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
          textStyle: { color: chartColor, fontFamily: fontFamily },
          iconStyle: { borderColor: chartColor },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLabel: { color: chartColor, fontFamily: fontFamily },
          data: stats?.usage_chart?.x_axis ?? [],
        },
        yAxis: {
          type: "value",
          axisLabel: { color: chartColor, fontFamily: fontFamily },
          splitLine: {
            lineStyle: {
              color: "#374151",
            },
          },
        },
        series: Object.keys(stats?.usage_chart?.plot).map((key) => {
          return {
            name: key,
            type: "line",
            // stack: "Total",
            // smooth: true,
            data: stats?.usage_chart?.plot[key],
          };
        }),
      });
    }
  }, [stats?.usage_chart]);
  return (
    <div className='usage-box-design'>
      <div className='box-items box-items-col'>
      <ReactECharts option={option} />
      </div>
    </div>
  )
}

