import React, { useEffect, useState } from "react";
import "./chatbot.scss";
import Chatbotheader from "./chatbotheader";
import Chatbotbody from "./chatbotbody";
import Chatbotfooter from "./chatbotfooter";
import BackGroundImage from "../../assets/images/chat-body.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getBrandInfo,
  getProjectById,
  setSelectedProject,
} from "../../store/slices/projectSlice";
import { useLocation, useParams } from "react-router-dom";
import { newShade } from "../../common/validation";
import { getUploadedFile } from "../../store/slices/embeddingSlice";

export default function Chatbot({ userChatById, isKnowledgeChat }) {
  const { selectedProject } = useSelector((state) => state.project);
  const [tempUserData, setTempUserData] = useState("");
  const [chatWithBot, setChatWithBot] = useState([]);
  const [bgImage, setBgImage] = useState();
  useEffect(() => {
    const BotBackgroundImage =
      selectedProject?.background_image instanceof Blob ||
      selectedProject?.background_image instanceof File
        ? URL.createObjectURL(selectedProject?.background_image)
        : typeof selectedProject?.background_image == "string"
        ? selectedProject?.background_image
        : BackGroundImage;
    setBgImage(BotBackgroundImage);
  }, [selectedProject?.background_image]);

  // typeof selectedProject?.background_image=="object"&&URL?.createObjectURL(selectedProject?.background_image)
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { id, projectId } = useParams();

  useEffect(() => {
    if (pathname.includes("generalchat") || pathname.includes("/chat/")) {
      const body = {
        id: id,
      };
      dispatch(getBrandInfo(body))
        .unwrap()
        .then((res) => {
          dispatch(setSelectedProject(res?.data?.data));
        });
    }
  }, []);
  const BgColor = newShade(selectedProject?.base_color, 80);
  useEffect(() => {
    getAllUploadFile();
  }, []);
  const getAllUploadFile = () => {
    let body = {
      offset: 0,
      project_id: projectId,
    };
    dispatch(getUploadedFile(body));
  };
  return (
    <div
      className="chat-bot-design chat-bot-design-lg"
      style={{
        backgroundImage: `url(${bgImage || BackGroundImage})`,
      }}
    >
      <Chatbotheader
        BgColor={BgColor}
        tempUserData={tempUserData}
        setTempUserData={setTempUserData}
        chatWithBot={chatWithBot}
        setChatWithBot={setChatWithBot}
      />
      <Chatbotbody
        userChatById={userChatById}
        isKnowledgeChat={isKnowledgeChat}
        tempUserData={tempUserData}
        setTempUserData={setTempUserData}
        chatWithBot={chatWithBot}
        setChatWithBot={setChatWithBot}
      />
      <Chatbotfooter BgColor={BgColor} />
    </div>
  );
}
