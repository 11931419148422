import React, { useState } from "react";
import "./chathistory.scss";
import Chathistorytable from "./chathistorytable";
import Chatbot from "../../../components/chatbot";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getChatByUser, getChatUser } from "../../../store/slices/projectSlice";
import { useParams } from "react-router-dom";
export default function Chathistory() {
  const [userChatById,setUserChatById]=useState([])

  const dispatch = useDispatch();
  const { projectId } = useParams();
  useEffect(() => {
    const body = {
      project_id: projectId,
    };
    dispatch(getChatUser(body));
  }, []);
  const handleOnClickChat = (data) => {
    let body = {
      session_id: data?.session_id,
      project_id: projectId,
    };
    dispatch(getChatByUser(body))
      .unwrap()
      .then((res) => {
        setUserChatById(res?.data?.data)
      });
  };
  return (
    <div className="chat-history-all-contnet-alignment">
      <div className="flex-box">
        <div className="flex-box-items">
          <Chathistorytable handleOnClickChat={handleOnClickChat} />
        </div>
        <div className="flex-box-items">
          <Chatbot userChatById={userChatById}/>
        </div>
      </div>
    </div>
  );
}
