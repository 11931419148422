import React, { useEffect, useState } from "react";
import "./knowledgesources.scss";
import Button from "../../../components/button";
import PdfIcon from "../../../assets/icons/pdffile.svg";
import DocIcon from "../../../assets/icons/docfile.svg";
import TextIcon from "../../../assets/icons/textfile.svg";
import SimpleTextIcon from "../../../assets/icons/simpletext.svg";
import NoDataImage from "../../../assets/images/no-da.png";
import RemoveIcon from "../../../assets/icons/remove.svg";
import Createknowledgesource from "../../../components/modal/createknowledgesource";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFile,
  getUploadedFile,
} from "../../../store/slices/embeddingSlice";
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import toast from "react-hot-toast";
export default function Knowledgesources({
  createknowledgesource,
  setCreateknowledgesource,
  deleteKnowledgeSource,
  setDeleteKnowledgeSource,
  deletedData,
  setDeletedData,
}) {
  const { embeddingFile } = useSelector((state) => state.embedding);

  const dispatch = useDispatch();
  const { projectId } = useParams();
  useEffect(() => {
    getAllUploadFile();
  }, []);
  const getAllUploadFile = () => {
    let body = {
      offset: 0,
      project_id: projectId,
    };
    dispatch(getUploadedFile(body));
  };
  const fileIcon = {
    text_file: TextIcon,
    pdf_file: PdfIcon,
    doc_file: DocIcon,
    simple_text: SimpleTextIcon,
  };

  return (
    <>
      <div className="knowledgesources-all-contnet-alignment">
        <div className="content-align">
          <p>Knowledge Sources</p>
          <Button
            text="+ Add New Source"
            onClick={() => setCreateknowledgesource(!createknowledgesource)}
          />
        </div>
        <div className="table-design">
          <table>
            <thead>
              <tr>
                <th align="center">Sr. no.</th>
                <th align="left">Source type</th>
                <th align="left">Source</th>
                <th align="left">Date</th>
                <th align="center">Action</th>
              </tr>
            </thead>
            <tbody>
              {embeddingFile?.length > 0 ? (
                embeddingFile?.map((item, index) => {
                  var date = new Date(`${item?.created_at} UTC`);
                  const createdOn = date.toString();
                  return (
                    <tr>
                      <td align="center">
                        <span>{index + 1}</span>
                      </td>
                      <td align="left">
                        <img
                          src={fileIcon[item?.type]}
                          alt="PdfIcon"
                          style={{ height: "25px", width: "25px" }}
                        />
                      </td>
                      <td align="left">
                        <span>{item?.input_file_name}</span>
                      </td>
                      <td align="left">
                        <span>{moment(createdOn).format("lll")}</span>
                      </td>
                      <td
                        align="center"
                        onClick={() => {
                          setDeletedData(item);
                          setDeleteKnowledgeSource(true);
                        }}
                      >
                        <img src={RemoveIcon} alt="RemoveIcon" />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5}>
                    <div className="no-knowledge-found">
                      <div className="image-center">
                        <img src={NoDataImage} alt="NoDataImage" />
                      </div>
                      <h4>No Knowledge Source</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Morbi feugiat sem at ligula varius maximus.
                      </p>
                      <div className="btn-center">
                        <Button
                          text="+ Add New Source"
                          onClick={() =>
                            setCreateknowledgesource(!createknowledgesource)
                          }
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
