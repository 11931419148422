import React from "react";
import "./userinformation.scss";
import LeeterIcon from "../../../assets/icons/leeter.svg";
import DolorIcon from "../../../assets/icons/dolor.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
export default function Userinformation() {
  const { selectedPlan } = useSelector((state) => state.subscription);
  const navigate=useNavigate()
  return (
    <div className="user-information-all-contnet-alignment">
      <div className="card">
        <div>
          <img src={LeeterIcon} alt="LeeterIcon" />
        </div>
        <div>
          <p>Message uses</p>
          <span>
            {selectedPlan?.total_meassges - selectedPlan?.remaining_messages}/
            {selectedPlan?.total_meassges}
          </span>
        </div>
      </div>
      <div className="card">
        <div>
          <img src={DolorIcon} alt="DolorIcon" />
        </div>
        <div style={{cursor:'pointer'}}>
          <div className="new-icon-text-alignment"  onClick={()=>navigate("/settings/subscription")}>
            <p className="pink-text-color">Your Plan</p>
            <div>
            <svg
              width="14"
              height="12"
              viewBox="0 0 14 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.72917 7.15804L4.56702 8.55654C4.53753 8.73699 4.65547 8.91744 4.83237 8.94752C5.51048 9.09789 6.23283 9.17308 6.99939 9.17308C7.76596 9.17308 8.4883 9.09789 9.16642 8.94752C9.34332 8.91744 9.46125 8.73699 9.43177 8.55654L9.26961 7.15804C9.21065 6.61668 8.91581 6.16555 8.4883 5.90991C8.07554 6.24074 7.55958 6.45127 6.99939 6.45127C6.43921 6.45127 5.92325 6.25578 5.51048 5.90991C5.08297 6.16555 4.78814 6.63172 4.72917 7.15804Z"
                fill="#A855EB"
              />
              <path
                d="M6.99933 5.63924C7.87862 5.63924 8.59143 4.91213 8.59143 4.01518C8.59143 3.11823 7.87862 2.39111 6.99933 2.39111C6.12003 2.39111 5.40723 3.11823 5.40723 4.01518C5.40723 4.91213 6.12003 5.63924 6.99933 5.63924Z"
                fill="#A855EB"
              />
              <path
                d="M3.07788 5.66919C3.01892 5.54889 2.90098 5.4737 2.76831 5.4737H2.01648C2.26709 2.90227 4.40463 0.887221 6.99917 0.887221C8.65024 0.887221 10.2129 1.72933 11.1416 3.12783C11.2743 3.32332 11.5396 3.38347 11.746 3.24813C11.9376 3.11279 11.9966 2.84211 11.8639 2.63159C10.7583 0.992484 8.94507 0 6.99917 0C3.9329 0 1.41207 2.40602 1.14672 5.4737H0.350672C0.217996 5.4737 0.100063 5.54889 0.0410962 5.66919C-0.0178706 5.78949 -0.0178705 5.92483 0.0705796 6.04513L1.2794 7.81957C1.33836 7.9098 1.4563 7.96995 1.57423 7.96995C1.69216 7.96995 1.79536 7.9098 1.86906 7.81957L3.07788 6.04513C3.12211 5.93987 3.13685 5.78949 3.07788 5.66919Z"
                fill="#A855EB"
              />
              <path
                d="M13.9431 5.95475L12.7343 4.18031C12.6016 3.98482 12.2921 3.98482 12.1594 4.18031L10.9506 5.95475C10.8769 6.06001 10.8621 6.21039 10.9211 6.33069C10.98 6.45099 11.098 6.52618 11.2306 6.52618H11.9825C11.7319 9.09762 9.59432 11.1127 6.99979 11.1127C5.34872 11.1127 3.7861 10.2706 2.85737 8.87205C2.7247 8.67656 2.45935 8.61641 2.25296 8.75175C2.06132 8.88709 2.00236 9.15777 2.13503 9.3683C3.24066 11.0074 5.05388 11.9999 6.99979 11.9999C10.0661 11.9999 12.5869 9.59386 12.8522 6.52618H13.6483C13.781 6.52618 13.8989 6.45099 13.9579 6.33069C14.0168 6.21039 14.0168 6.06001 13.9431 5.95475Z"
                fill="#A855EB"
              />
            </svg>
            </div>
          </div>

          <span>{selectedPlan?.name}</span>
        </div>
      </div>
    </div>
  );
}
