import React from 'react'
import './profile.scss';
import ProfileImage from '../../../assets/icons/profile.svg';
import { getUser } from '../../../helpers/utils/auth.util';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
export default function Profile() {
  // const { user } = useSelector((state) => state.auth);
  const user=getUser()
  return (
    <div className='header-profile'>
      <img src={ProfileImage} alt="ProfileImage"/>
      <div>
        <p>{user?.name}</p>
        <NavLink to="/settings/account">Settings</NavLink>
      </div>
    </div>
  )
}
