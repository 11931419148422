import React, { useEffect } from "react";
import Authheader from "../../components/authheader";
import AuthVector from "../../assets/icons/auth-vector.svg";
import "./verifyemail.scss";
import Button from "../../components/button";
import Loginwithgoogle from "../../components/loginwithgoogle";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resendOtp,  verifyOtp } from "../../store/slices/authSlice";
import { useState } from "react";
import OTPInput from "../../common/OTPInput";
import toast from "react-hot-toast";
export default function VerifyEmail() {
  const [otp, setotp] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState();
  const [buttonResend, setButtonResend] = useState(false);
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let resendTime = 60;
  let secondsSinceLastActivity = resendTime;
  useEffect(() => {
    if (!buttonResend) {
      let date = setInterval(() => {
        secondsSinceLastActivity--;
        setTimer(secondsSinceLastActivity);
        if (secondsSinceLastActivity === 0) {
          setButtonResend(true);
        }
      }, 1000);
      return () => {
        clearInterval(date);
      };
    }
  }, [secondsSinceLastActivity, buttonResend]);
  const handleOnChange = (data) => {
    setotp({ ...otp, otp: data });
    setErrors({ ...errors, otp: "" });
  };

  const validForm = () => {
    let Fromvlid = true;
    let errors = {};
    if (!otp.otp?.trim() || otp?.otp?.length < 6) {
      Fromvlid = false;
      errors["otp"] = "Please enter valid OTP*";
    }

    setErrors(errors);
    return Fromvlid;
  };
  const handleVerifyOtp = () => {
    if (!validForm()) return;
    setLoading(true);
    const body = {
      token: token,
      ...otp,
    };
    dispatch(verifyOtp(body))
      .unwrap()
      .then((res) => {
        if (!res?.data?.success) {
          setLoading(false);
          setErrors({ ...errors, otp: res?.data?.message });
          return;
        }
        setLoading(false);
        navigate("/");
      });
  };
  const handleReSendOtp = async (e) => {
    e.preventDefault();

    const body={
      token:token
    }
    dispatch(resendOtp(body))
      .unwrap()
      .then((data) => {
        if (data.success) {
          toast.success(data.data.message);
          secondsSinceLastActivity = resendTime;
          setButtonResend(false);
          setTimer(resendTime);
        } else if (!data.success) {
          toast.error(data.error);
        }
      })
      .catch(() => {
        toast.error("something went wrong try again !");
      });
  };
  return (
    <div className="auth-layout">
      <div className="box">
        <Authheader />
        <div className="auth-body-alignment">
          <div className="grid">
            <div className="grid-items">
              <div className="image">
                <img src={AuthVector} alt="AuthVector" />
              </div>
            </div>
            <div className="grid-items">
              <div className="input-bottom-alignment">
                {/* <Input label="OTP" placeholder="Enter your OTP" name={"otp"} onChange={handleOnChange} error={errors?.otp}/> */}
                <form className="otp_form" onSubmit={handleVerifyOtp}>
                  <label style={{ color: "white", margin: "0 10px" }}>
                    Enter OTP
                  </label>
                  <div className="otp_row">
                    <OTPInput
                      isNumberInput
                      autoFocus
                      length={6}
                      className="input_col"
                      inputClassName="otp_input"
                      onChangeOTP={(otp) => handleOnChange(otp)}
                      handleSubmitOTP={handleVerifyOtp}
                    />
                  </div>
                  {errors["otp"] && (
                    <span
                      style={{
                        color: "red",
                        fontSize: "10px",
                        padding: "0px 10px",
                      }}
                    >
                      {errors["otp"]}
                    </span>
                  )}

                  <div className="otp-text" style={{display:'flex',gap:'5px',marginLeft:'10px'}}>
                    <span>Haven't received OTP ?</span>
                    {timer > 0 ? (
                     
                      <span className="green-text opacity1">After {timer} seconds <u className="green-text">Try Again</u></span>
                    ) : (
                      <div onClick={(e) => handleReSendOtp(e)}>
                      
                       <span className="green-text opacity1">
                        <u>Resend Now</u>
                       </span>
                      </div>
                    )}
                  </div>
                </form>
              </div>

              <div className="signup-button-alignment">
                {/* <NavLink to="/dashboard"> */}
                <Button
                  text="Verify"
                  onClick={handleVerifyOtp}
                  loader={loading}
                />
                {/* </NavLink> */}
              </div>
              <div className="text-grid">
                <div className="line"></div>
                <span>OR</span>
                <div className="line"></div>
              </div>
              <div className="google-contnet-alignment">
                <Loginwithgoogle />
              </div>
              <div className="last-text">
                <span>
                  You have an account ? <NavLink to="/login">Sign In</NavLink>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
