import React from "react";
import { createBrowserRouter } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import SignedInRoute from "./SignedInRoute";
import Login from "../routes/login";
import VerifyEmail from "../routes/verifyEmail";
import Dashboard from "../routes/dashboard";
import DefaultLayout from "../layout/defaultLayout/defaultLayout";
import Settings from "../routes/settings";
import Stats from "../routes/stats";
import Signup from "../routes/signup";
import ForgotPassword from "../routes/forgotPassword";
import ChangePassword from "../routes/changePassword";
import Chatbot from "../components/chatbot";



const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <SignedInRoute>
        <Login />
      </SignedInRoute>
    ),
  },
  {
    path: "/signup",
    element: (
      <SignedInRoute>
        <Signup />
      </SignedInRoute>
    ),
  },
  {
    path: "/verify-email/:token",
    element: (
      <SignedInRoute>
        <VerifyEmail />
      </SignedInRoute>
    ),
  },
  // {
  //   path: "/forgot-password",
  //   element: (
  //     <SignedInRoute>
  //       <ForgotPassword />
  //     </SignedInRoute>
  //   ),
  // },
  // {
  //   path: "/reset-password",
  //   element: (
  //     <SignedInRoute>
  //       <ResetPassword />
  //     </SignedInRoute>
  //   ),
  // },
  {
    path: "/verification-code",
    element: (
      <SignedInRoute>
        <VerifyEmail />
      </SignedInRoute>
    ),
  },
  {
    path: "/forgot-pasword",
    element: (
      <SignedInRoute>
        <ForgotPassword />
      </SignedInRoute>
    ),
  },
  {
    path: "/change-pasword/:token",
    element: (
      <SignedInRoute>
        <ChangePassword />
      </SignedInRoute>
    ),
  },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <DefaultLayout>
          <Dashboard />
        </DefaultLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <DefaultLayout>
          <Dashboard />
        </DefaultLayout>
      </ProtectedRoute>
    )},
    {
    path: "/project/:route/:projectId",
    element: (
      <ProtectedRoute>
        <DefaultLayout>
          <Stats />
        </DefaultLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: "/generalchat/:id",
    element: (
      // <ProtectedRoute>
        // <DefaultLayout>
          <Chatbot />
        // </DefaultLayout>
      // </ProtectedRoute>
    ),
  },
  {
    path: "/chat/:id",
    element: (
      // <ProtectedRoute>
        // <DefaultLayout>
          <Chatbot />
        // </DefaultLayout>
      // </ProtectedRoute>
    ),
  },
  {
    path: "/settings/:route",
    element: (
      <ProtectedRoute>
        <DefaultLayout>
          <Settings />
        </DefaultLayout>
      </ProtectedRoute>
    ),
  },
]);

export default router;
