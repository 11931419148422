import React, { useState } from "react";
import "./contactus.scss";
import Closeicon from "../common/closeicon";
import Input from "../../input";
import Textarea from "../../textarea";
import Button from "../../button";
import { useDispatch } from "react-redux";
import { contactUs } from "../../../store/slices/planSlice";
import toast from "react-hot-toast";
export default function Contactus({ onClose,isModal,userData, setUserData,error, setErrors }) {

  const dispatch = useDispatch();

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
    setErrors({ ...error, [name]: "" });
  };
  const validForm = () => {
    let Fromvlid = true;
    let errors = {};
    if (!userData.name?.trim()) {
      Fromvlid = false;
      errors["name"] = "Name is required*";
    }
    if (!userData.email?.trim()) {
      Fromvlid = false;
      errors["email"] = "Email Id is required*";
    }else {
      let lastAtPos = userData.email.lastIndexOf("@");
      let lastDotPos = userData.email.lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          userData.email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          userData.email.length - lastDotPos > 2
        )
      ) {
        Fromvlid = false;
        errors["email"] = "Please enter valid Email ID";
      }
    }

    if (!userData.phone_number?.trim()) {
      Fromvlid = false;
      errors["phone_number"] = "Phone number is required*";
    }
    if (!userData.purpose?.trim()) {
      Fromvlid = false;
      errors["purpose"] = "Company Details is required*";
    }
    setErrors(errors);
    return Fromvlid;
  };
  const handleSubmit = () => {
    if(!validForm()) return;
    let body = {
      ...userData,
    };
    dispatch(contactUs(body))
      .unwrap()
      .then((res) => {
        if (!res?.data?.success) {
          toast.error(res?.data?.message);
          return;
        }
        toast.success(res?.data?.message);
        onClose();
      });
  };

  return (
    <div className={`contact-us-modal-wrapper ${isModal &&'openModalWrapper'}`}>
      <div className="modal">
        <div className="modal-header">
          <p>Contact Us</p>
          <Closeicon onClick={() => onClose && onClose()} />
        </div>
        <div className="modal-body">
          <div className="bottom-space">
            <Input
              label="Full Name"
              name={"name"}
              placeholder="Enter Your Full Name"
              value={userData?.name}
              onChange={handleOnChange}
              error={error?.name}
            />
          </div>
          <div className="bottom-space">
            <Input
              label="Email"
              name={"email"}
              placeholder="Enter Your Email"
              value={userData?.email}
              onChange={handleOnChange}
              error={error?.email}
            />
          </div>
          <div className="bottom-space">
            <Input
            type={"number"}
              label="Phone Number"
              name={"phone_number"}
              placeholder="Enter Your Number"
              value={userData?.phone_number}
              onChange={handleOnChange}
              error={error?.phone_number}
            />
          </div>
          <div className="bottom-space">
            <Textarea
              label="Company Details"
              name={"purpose"}
              value={userData?.purpose}
              placeholder="Enter Your Company Details"
              onChange={handleOnChange}
              error={error?.purpose}
            />
          </div>
          <div className="btn-center">
            <Button text="Contact Us" onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
}
