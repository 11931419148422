import React from "react";
import styles from "./upgradeplanmodal.scss";
import Closeicon from "../common/closeicon";
import UpgradePlanImage from '../../../assets/images/upgrade-plan.png';
import Button from "../../button";
export default function Upgradeplanmodal() {
  return (
    <div>
      <div className="modal-wrapper-upgrade-plan">
        <div className="modal">
            <div className="modal-header">
                <p>Upgrade Your Plan</p>
                <Closeicon  />
            </div>
            <div className="modal-body-alignment">
                <div className="image-center-alignment">
                    <img src={UpgradePlanImage} alt="UpgradePlanImage"/>
                </div>
                <p>
                Please your upgrade your dhibot subscription plan.
                </p>
                <div className="btn-center-alignment">
                    <Button text="Upgrade" />
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}
