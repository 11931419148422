import React from 'react'
import './closeicon.scss';
import CloseIcon from '../../../../assets/icons/close-icon.svg';
export default function Closeicon({onClick}) {
  return (
    <div className='modal-close-icon' onClick={onClick}>
      <img src={CloseIcon} alt="CloseIcon"/>
    </div>
  )
}
