import React from "react";
import "./textarea.scss";
export default function Textarea({ label, placeholder, icon,onChange,name,value,error,disabled }) {
  return (
    <div className="textarea">
      <label>{label}</label>
      <div className="box">
     <textarea placeholder={placeholder} name={name} value={value} onChange={(e)=>onChange&&onChange(e)} disabled={disabled?disabled:false}></textarea>
     </div>
     <div className="last-text">
{      <span style={{color:'red',fontSize:'10px'}}>{error}</span>||
      <span>{"*please paste this code into body element to take effect"}</span>}
     </div>
    </div>
  );
}
