import React from 'react'
import './statsdetails.scss';
import Icon1  from '../../../assets/icons/1.svg';
import Icon2  from '../../../assets/icons/2.svg';
import Icon3  from '../../../assets/icons/3.svg';
import Usage from './usage';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getStats } from '../../../store/slices/projectSlice';
import { useParams } from 'react-router-dom';
export default function Statsdetails() {
  const {stats}=useSelector((state)=>state.project)
  const dispatch=useDispatch()
  const {projectId}=useParams()
  useEffect(()=>{
    const body={
      id:projectId
    }
    dispatch(getStats(body))

  },[])
  return (
    <div className='stats-details-all-contnet-alignment'>
      <div className='grid'>
        <div className='grid-items'>
            <img src={Icon1} alt="Icon1"/>
            <p>Estimate characters replied</p>
            <span>{stats?.total_chars || 0}</span>
        </div>
        <div className='grid-items'>
            <img src={Icon2} alt="Icon2"/>
            <p>Number of sessions</p>
            <span>{stats?.num_sessions || 0}</span>
        </div>
        <div className='grid-items'>
            <img src={Icon3} alt="Icon3"/>
            <p>Total messages served</p>
            <span>{stats?.num_questions || 0}</span>
        </div>
        {/* <div className='grid-items'>
            <img src={Icon4} alt="Icon4"/>
            <p>Total billed amount</p>
            <span>$ {stats?.total_cost?.toFixed(4)|| 0}</span>
        </div> */}
      </div>
      <Usage/>
    </div>
  )
}
