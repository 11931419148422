import React, { useState, useEffect } from "react";
import "./header.scss";
import Logo from "../../assets/logo/sm-logo.svg";
import LogoutIconHeader from "../../assets/icons/logout.svg";
import Profile from "./profile";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";
import Conformation from "../../components/modal/conformation";
import LogoutIcon from "../../assets/icons/logout-icon.svg";
import TajIcon from "../../assets/icons/taj.svg";
import { deleteToken, deleteuser } from "../../helpers/utils/auth.util";

const AnimatedIcon = ({ isOpen, onClick }) => {
  return (
    <div onClick={onClick} className="iconContainer">
      <span
        className={`line ${isOpen ? "lineOpenTop" : "lineCloseTop"}`}
      ></span>
      <span
        className={`line ${isOpen ? "lineOpenMiddle" : "lineCloseMiddle"}`}
      ></span>
      <span
        className={`line ${isOpen ? "lineOpenBottom" : "lineCloseBottom"}`}
      ></span>
    </div>
  );
};

export default function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmationModel, setIsConfirmationModel] = useState(false);
  const handleLogout = () => {
    deleteuser();
    deleteToken();
    dispatch(logout());
    navigate("/login");
  };
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isOpen]);

  const handleCloseModel = () => {
    setIsConfirmationModel(!isConfirmationModel);
  };
  return (
    <>
      <header>
        <div className="container">
          <div className="header-alignment">
            <div className="logo">
              <NavLink to="/">
                <img src={Logo} alt="Logo" />
              </NavLink>
            </div>
            {/* <Menu /> */}
            <div className="other-contnet-alignment">
              <button onClick={() => navigate("/settings/subscription")}>
                <img src={TajIcon} alt="TajIcon" />
                Upgrade Account
              </button>
              <Profile />
              <div
                className="logout"
                onClick={() => setIsConfirmationModel(!isConfirmationModel)}
              >
                <img src={LogoutIconHeader} alt="LogoutIcon" />
              </div>
            </div>
            {/* <i class="fa-solid fa-bars"></i> */}
            <div className="mobile-menu-hidden">
              <AnimatedIcon
                isOpen={isOpen}
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              />
            </div>
          </div>
        </div>
      </header>
      <div className={`mobile-view-sidbar ${isOpen ? "show" : "hide"}`}>
        <div className="mobile-body-new-all-contnet-alignment">
          <NavLink to="/settings/account" onClick={() => setIsOpen(false)}>
            Settings
          </NavLink>
          {/* <a onClick={() => setIsOpen(false)}>
          Upgrade Account
          </a> */}
          <NavLink onClick={() => handleLogout()}>Logout</NavLink>
          <div className="other-contnet-alignment">
            <Profile />
          </div>
          <div className="m-new-bt">
            <button>
              <img src={TajIcon} alt="TajIcon" />
              Upgrade Account
            </button>
          </div>
        </div>
      </div>

      <Conformation
        title={"Logout"}
        btnText={"Logout"}
        onClick={handleLogout}
        onClose={handleCloseModel}
        message={"Are you sure, you want to logout?"}
        icon={LogoutIcon}
        isModal={isConfirmationModel}
      />
    </>
  );
}
