import React from "react";
import "./changepassword.scss";
import Closeicon from "../common/closeicon";
import Input from "../../input";
import Textarea from "../../textarea";
import Button from "../../button";
import { useDispatch, useSelector } from "react-redux";
import { chnagePassword } from "../../../store/slices/authSlice";
import { useState } from "react";
import EyeIcon from "../../../assets/icons/eye.svg";
import HideEyeIcon from "../../../assets/icons/hideeye.svg";
import toast from "react-hot-toast";
import { checkPasswordValidation } from "../../../common/validation";
import { getUser } from "../../../helpers/utils/auth.util";
export default function Changepassword({
  changePasswordModel,
  setChangePasswordModel,
}) {
  // const { user } = useSelector((state) => state.auth);
  const user=getUser()
  const dispatch = useDispatch();
  const [password, setPassword] = useState({});
  const [errors, setErrors] = useState({});
  const [loading,setLoading]=useState(false)

  const [showEyeIcon, setShowEyeIcon] = useState([
    { show: false },
    { show: false },
    { show: false },
  ]);
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setPassword({ ...password, [name]: value });
  };
  const validForm = () => {
    let Fromvlid = true;
    let errors = {};

    if (user?.providers !== "google" && !password.old_password?.trim()) {
      Fromvlid = false;
      errors["old_password"] = "Old Password is required*";
    }
    if (!password.new_password?.trim()) {
      Fromvlid = false;
      errors["new_password"] = "New Password is required*";
    }
    if (!password.confirm_password?.trim()) {
      Fromvlid = false;
      errors["confirm_password"] = "Confirm Password is required*";
    }
    if (password.new_password !== password.confirm_password) {
      Fromvlid = false;
      errors["confirm_password"] = "Password does not match with new password*";
    } else {
      if (checkPasswordValidation(password.new_password) !== null) {
        Fromvlid = false;
        errors["confirm_password"] = checkPasswordValidation(
          password.new_password
        );
      }
    }

    setErrors(errors);
    return Fromvlid;
  };
  const handleChangePAssword = () => {
    if (!validForm()) return;
    setLoading(true)
    const body = {
      ...password,
    };
    dispatch(chnagePassword(body))
      .unwrap()
      .then((res) => {
        if (res.status !== 200) {
          toast.error(res?.data?.detail);
          setLoading(false)
          return;
        }
        setLoading(false)
        toast.success(res?.data?.message);
        setChangePasswordModel(!changePasswordModel);
      });
  };

  const handleShowEyeIcon = (index) => {
    let value = [...showEyeIcon];
    value[index].show = !value[index]?.show;
    setShowEyeIcon(value);
  };
  return (
    <div>
      <div className="modal-wrapper-change-password">
        <div className="change-password">
          <div className="modal-header">
            <p>Change Password</p>
            <Closeicon onClick={() => setChangePasswordModel(false)} />
          </div>
          <div className="modal-body">
            {user?.providers !== "google" && (
              <div className="input-bottom-alignment">
                <Input
                  type={showEyeIcon[0].show ? "text" : "password"}
                  label="Old Password"
                  name={"old_password"}
                  value={password?.old_password}
                  placeholder="Enter Old Password"
                  onChange={handleOnChange}
                  icon={showEyeIcon[0].show ? HideEyeIcon : EyeIcon}
                  error={errors?.old_password}
                  iconClick={() => handleShowEyeIcon(0)}
                />
              </div>
            )}
           <div className="input-bottom-alignment">
              <Input
                type={showEyeIcon[1].show ? "text" : "password"}
                label="New Password"
                name={"new_password"}
                value={password?.new_password}
                placeholder="Enter New Password"
                onChange={handleOnChange}
                icon={showEyeIcon[1].show ? HideEyeIcon : EyeIcon}
                error={errors?.new_password}
                iconClick={() => handleShowEyeIcon(1)}
              />
            </div>
            <div className="input-bottom-alignment">
              <Input
                type={showEyeIcon[2].show ? "text" : "password"}
                label="Confirm Password"
                name={"confirm_password"}
                value={password?.confirm_password}
                placeholder="Enter Confirm Password"
                onChange={handleOnChange}
                icon={showEyeIcon[2].show ? HideEyeIcon : EyeIcon}
                error={errors?.confirm_password}
                iconClick={() => handleShowEyeIcon(2)}
              />
            </div>
            <div className="button-right-alignment">
              <Button text="Update Password" onClick={handleChangePAssword} loader={loading} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
