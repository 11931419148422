import React from 'react'
import './authheader.scss';
import Logo from '../../assets/logo/logo.svg';
export default function Authheader() {
  return (
    <div className='auth-header'>
      <div className='logo'>
        <img src={Logo} alt="Logo"/>
      </div>
      <div className='text'>
        <p>Nice to see you here!, please provide your credentials to continue</p>
      </div>
    </div>
  )
}
