import React from "react";
import Authheader from "../../components/authheader";
import AuthVector from "../../assets/icons/auth-vector.svg";
import Input from "../../components/input";
import Button from "../../components/button";
import Loginwithgoogle from "../../components/loginwithgoogle";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../store/slices/authSlice";
import { useState } from "react";
import toast from "react-hot-toast";
export default function ForgotPassword() {
  const [forgotInfo, setForgotInfo] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setForgotInfo({ ...forgotInfo, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validForm = () => {
    let Fromvlid = true;
    let errors = {};

    if (!forgotInfo.email?.trim()) {
      Fromvlid = false;
      errors["email"] = "Email Id is required*";
    } else {
      let lastAtPos = forgotInfo.email.lastIndexOf("@");
      let lastDotPos = forgotInfo.email.lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          forgotInfo.email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          forgotInfo.email.length - lastDotPos > 2
        )
      ) {
        Fromvlid = false;
        errors["email"] = "Please enter valid Email ID";
      }
    }
    setErrors(errors);
    return Fromvlid;
  };
  const handleSignup = () => {
    if (!validForm()) return;
    setLoading(true);
    const body = {
      token: token,
      ...forgotInfo,
    };
    dispatch(forgotPassword(body))
      .unwrap()
      .then((res) => {
        if (!res?.data?.success) {
          setLoading(false);
          return;
        }
        if (res?.data?.success) {
          setLoading(false);
          // navigate(`/change-pasword/${res?.data?.data}`)
          toast.success("Password reset link send to registered email");
          navigate("/");
        }
      });
  };

  return (
    <div className="auth-layout">
      <div className="box">
        <Authheader />
        <div className="auth-body-alignment">
          <div className="grid">
            <div className="grid-items">
              <div className="image">
                <img src={AuthVector} alt="AuthVector" />
              </div>
            </div>
            <div className="grid-items">
              <div className="input-bottom-alignment">
                <Input
                  label="Email"
                  placeholder="Enter Your Email"
                  name={"email"}
                  onChange={handleOnChange}
                  error={errors?.email}
                />
              </div>

              <div className="signup-button-alignment">
                {/* <NavLink to="/dashboard"> */}
                <Button
                  text="Forgot Password"
                  onClick={handleSignup}
                  loader={loading}
                />
                {/* </NavLink> */}
              </div>
              <div className="text-grid">
                <div className="line"></div>
                <span>OR</span>
                <div className="line"></div>
              </div>
              <div className="google-contnet-alignment">
                <Loginwithgoogle />
              </div>
              <div className="last-text">
                <span>
                  You have an account ? <NavLink to="/login">Sign In</NavLink>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
