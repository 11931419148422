import React from "react";
import "./chatbotfooter.scss";
import { hexToRgb } from "../../../common/validation";
import { useSelector } from "react-redux";
export default function Chatbotfooter({BgColor}) {
  const { selectedProject } = useSelector((state) => state.project);


  const BaseColor = hexToRgb(selectedProject?.base_color);
  return (
    <div
      className="chat-bot-footer"
      style={{
        background: `linear-gradient(180deg, ${BgColor} 0%, ${selectedProject?.base_color} 100%)`,
      }}
    >
      
<a  href="http://dhibot.com" target="_blank">

      <span style={{ color: selectedProject?.text_color }} >Powered By Dhibot</span>
</a>
      
    </div>
  );
}
