import React from "react";
import Authheader from "../../components/authheader";
import AuthVector from "../../assets/icons/auth-vector.svg";
import EyeIcon from "../../assets/icons/eye.svg";
import HideEyeIcon from "../../assets/icons/hideeye.svg";
import Input from "../../components/input";
import Button from "../../components/button";
import Loginwithgoogle from "../../components/loginwithgoogle";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signUp } from "../../store/slices/authSlice";
import { useState } from "react";
import { checkPasswordValidation } from "../../common/validation";
export default function Signup() {
const [signUpData,setSignupData]=useState({})
const [showEyeIcon,setShowEyeIcon]=useState(false)
const [loading,setLoading]=useState(false)
const [errors,setErrors]=useState({})
  const dispatch=useDispatch();
  const navigate=useNavigate();

  const handleOnChange=(e)=>{
  const {name,value}= e.target;
  setSignupData({...signUpData,[name]:value});
  setErrors({...errors,[name]:""})
  } 
  const validForm = () => {
    let Fromvlid = true;
    let errors = {};
    if (!signUpData.name?.trim()) {
      Fromvlid = false;
      errors["name"] = "Name is required*";
    }
    if (!signUpData.email?.trim()) {
      Fromvlid = false;
      errors["email"] = "Email Id is required*";
    }else {
      let lastAtPos = signUpData.email.lastIndexOf("@");
      let lastDotPos = signUpData.email.lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          signUpData.email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          signUpData.email.length - lastDotPos > 2
        )
      ) {
        Fromvlid = false;
        errors["email"] = "Please enter valid Email ID";
      }
    }

    if (!signUpData.password?.trim()) {
      Fromvlid = false;
      errors["password"] = "Password is required*";
    }else{
      if(checkPasswordValidation(signUpData.password)!==null){
        Fromvlid = false;
        errors["password"] = checkPasswordValidation(signUpData.password);
      }
    }
    setErrors(errors);
    return Fromvlid;
  };
const handleSignup=()=>{
  if(!validForm()) return;
  setLoading(true)
  dispatch(signUp(signUpData)).unwrap().then((res)=>{

    if(!res?.data?.success){
      setLoading(false)
      return};
      setLoading(false)
    navigate(`/verify-email/${res?.data?.data}`)
  });
}

  return (
    <div className="auth-layout">
      <div className="box">
        <Authheader />
        <div className="auth-body-alignment">
          <div className="grid">
            <div className="grid-items">
              <div className="image">
                <img src={AuthVector} alt="AuthVector" />
              </div>
            </div>
            <div className="grid-items">
              <div className="input-bottom-alignment">
                <Input label="Full Name" placeholder="Enter Your Full Name" name={"name"} onChange={handleOnChange} error={errors?.name}/>
              </div>
              <div className="input-bottom-alignment">
                <Input label="Email" placeholder="Enter Your Email" name={"email"} onChange={handleOnChange} error={errors?.email}/>
              </div>
              <Input
               type={showEyeIcon?"text":"password"} 
                label="Password"
                name={"password"}
                placeholder="Enter Password"
                icon={showEyeIcon?HideEyeIcon:EyeIcon}
                onChange={handleOnChange}
                error={errors?.password}
                iconClick={()=>setShowEyeIcon(!showEyeIcon)}
              />

              <div className="signup-button-alignment">
                {/* <NavLink to="/dashboard"> */}
                <Button text="Sign Up" onClick={handleSignup} loader={loading} />
                {/* </NavLink> */}
              </div>
              <div className="text-grid">
                <div className="line"></div>
                <span>OR</span>
                <div className="line"></div>
              </div>
              <div className="google-contnet-alignment">
                <Loginwithgoogle />
              </div>
              <div className="last-text">
                <span>
                  You have an account ? <NavLink to="/login">Sign In</NavLink>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
