import React from "react";
import "./chathistorytable.scss";
import NodataFound from "../../../../assets/images/no-data-vector.svg";
import {  useSelector } from "react-redux";
import moment from "moment";

export default function Chathistorytable({ handleOnClickChat }) {
  const { userChat } = useSelector((state) => state.project);

  return (
    <>
      <div className="chat-history-table-alignment">
        <h4>Chat History</h4>
        <div className="table-design">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {userChat?.length > 0 ? (
                userChat?.map((item) => {
                  var date = new Date(`${item?.created_at} UTC`);
                  const createdOn = date.toString();
                  return (
                    <tr onClick={() => handleOnClickChat(item)}>
                      <td>
                        <div className="image-text">
                          {/* <img src={Profile} alt="Profile" /> */}
                          <span>
                            {item?.name}
                          </span>
                        </div>
                      </td>
                      <td>
                        <span>{item?.email}</span>
                      </td>
                      <td>
                        <span>{moment(createdOn).format("lll")}</span>
                        
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={3}>
                    <div className="new-data-not-found-alignment">
                      <div className="image-center-alignment">
                        <img src={NodataFound} alt="NodataFound" />
                      </div>
                      <h4>No Chat History</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Morbi feugiat sem at ligula varius maximus.
                      </p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* <div className="pagination-top-alignment">
        <Pagination/>
      </div> */}
    </>
  );
}
