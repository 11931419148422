import React, { useEffect, useState } from "react";
import "./index.css";

import ImageModal from "../cropper/ImageModal";

const ImageInput = ({ name, onChange, showPreview, imageData, fileData,OriginalImage, setAddImgesModal ,addImageModal}) => {
  const [image, setImage] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);

  const onChangeHandler = (file) => {
    onChange({
      [name]: {
        // data: file[0],
        // src: URL.createObjectURL(file[0])
        data: fileData,
        src: file,
      },
    });
  };

  const handleFile = (e) => {
    if (e) {
      const file = e;
      var url = URL.createObjectURL(file);
      var img = new Image();
      img.src = url;
      img.onload = function () {
        setWidth(this.width);
        setHeight(this.height);
      };
      const maxAllowedSize = 10 * 1024 * 1024;
      if (file.size > maxAllowedSize) {

      } else {
        setImage(file);
        setModalIsOpen(true);
      }
      // e.target.value = null;
    }
    setAddImgesModal(false)
  };
  let inputElement;
  useEffect(()=>{
    if(addImageModal && fileData){

      handleFile(fileData)
    }
  },[fileData])

  return (
    <>
      <ImageModal
        modalIsOpen={modalIsOpen}
        closeModal={() => {
          setModalIsOpen((prevState) => !prevState);
        }}
        image={image}
        onCropImage={(croppedImg) => onChangeHandler(croppedImg)}
        ratio={height / width <= 0.5 ? true : false}
        OriginalImage={OriginalImage}
        setAddImgesModal={addImageModal}
      />
      {/* {showPreview && (
        <div className="crop-button-alignment" onClick={() => } key={imageData}>
          <p>Crop</p>
        </div>
      )} */}
      {/* <div className="upload-image" onClick={() => inputElement.click()}>
        Upload{" "}
      </div>
      <input
        ref={(input) => (inputElement = input)}
        accept="image/*"
        type="file"
        style={{ display: "none" }}
        onChange={handleFile}
      /> */}
    </>
  );
};

export default ImageInput;
