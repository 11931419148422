import React, { useState } from "react";
import "./createknowledgesource.scss";
import Closeicon from "../common/closeicon";
import Textarea from "../../textarea";

import Button from "../../button";
import TextIcon from "../../../assets/icons/text.svg";
import TextFileIcon from "../../../assets/icons/text-file.svg";
import PdfFileIcon from "../../../assets/icons/pdf-file.svg";
import DocIcon from "../../../assets/icons/doc.svg";
import DriveIcon from "../../../assets/icons/drive.svg";
import AirtableIcon from "../../../assets/icons/Airtable.svg";
import Chooseimage from "../../../routes/stats/chatbotsettings/chooseimage";
import { useDispatch } from "react-redux";
import { addFileUpload } from "../../../store/slices/embeddingSlice";
import { useParams } from "react-router-dom";
import { formDataHeader } from "../../../helpers/API/ApiData";
import toast from "react-hot-toast";
export default function Createknowledgesource(props) {
  const { setCreateknowledgesource, createknowledgesource } = props;
  const dispatch = useDispatch();
  const [fileType, setFileType] = useState("simpleText");
  const [fileUpload, setFileUpload] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors,setErrors]=useState("")
  const { projectId } = useParams();
  const handleClose = () => {
    setFileUpload("");
    setErrors("")
    setFileType("simpleText")
    setCreateknowledgesource(!createknowledgesource);
  };
  const handleFileUpload = (choice) => {
    setErrors("")
    setFileUpload("")
    setFileType(choice);
  };
const fileTypes={
  textFile:"TXT File",
  pdfFile:"Pdf File",
  docFile:"Docs File"
}
  const handleOnChange = (e) => {
    const { name, value, files } = e.target;
    if (fileType === "simpleText") {
      setFileUpload(value);
      setErrors("")
    } else if(fileType ==="textFile"){
      if(!files?.[0]?.name?.includes(".txt")){
        setErrors("Please upload Only Text file*")
        return;
      }
      setFileUpload(files[0]);
      setErrors("")
    }
    else if(fileType ==="pdfFile"){
      if(!files?.[0]?.name?.includes(".pdf")){
        setErrors("Please upload Only Pdf file*")
        return;
      }
      setFileUpload(files[0]);
      setErrors("")
    }
    else if(fileType ==="docFile"){
      if(!files?.[0]?.name?.includes(".docs")){
        setErrors("Please upload Only Docs file*")
        return;
      }
      setFileUpload(files[0]);
      setErrors("")
    }
  };
  const handleSubmit = () => {
    // if(fileType ==="simpleText"){
    //   setErrors("Please Enter Text")
    //   return;
    // }else if(fileType !=="simpleText"){
    //   setErrors("Please Upload File");
    //   return;
    // }
    setLoading(true);
    let payload = {
      data: { project_id: projectId },
    };

    switch (fileType) {
      case "simpleText":
        payload.route = "embedding/simple-text";
        payload.data.text = fileUpload;

        break;
      case "textFile":
        payload.route = "embedding/txt-file-embed";
        payload.headers = formDataHeader;
        payload.data.file = fileUpload;
        break;
      case "pdfFile":
        payload.route = "embedding/pdf-file-embed";
        payload.headers = formDataHeader;
        payload.data.file = fileUpload;
        break;
      case "docFile":
        payload.route = "embedding/doc-file-embed";
        payload.headers = formDataHeader;
        payload.data.file = fileUpload;
        break;
      default:
        return;
    }

    dispatch(addFileUpload(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data?.success) {
          toast.error(res?.data?.message);
          setLoading(false);
          return;
        }
        toast.success(res?.data?.message);
        handleClose();
        setLoading(false);
      })
  };

  const handleOnRemoveImage = () => {
    setFileUpload("");
  };

  return (
    <div>
      <div
        className={`createknowledgesource-modal-wrapper ${
          createknowledgesource ? "openModalWrapper" : ""
        }`}
      >
        <div className="modal">
          <div className="modal-header">
            <p>Create Knowledge Source</p>
            <Closeicon onClick={handleClose} />
          </div>
          <div className="modal-body">
            <div className="select-source-type-contnet-alignment">
              <h6>Select Source Type</h6>
              <div className="all-box-contnet-alignment">
                <div
                  className="box"
                  onClick={() => handleFileUpload("simpleText")}
                >
                  <img src={TextIcon} alt="TextIcon" />
                  <span>Simple Text</span>
                </div>
                <div
                  className="box"
                  onClick={() => handleFileUpload("textFile")}
                >
                  <img src={TextFileIcon} alt="TextFileIcon" />
                  <span>TXT file</span>
                </div>
                <div
                  className="box"
                  onClick={() => handleFileUpload("pdfFile")}
                >
                  <img src={PdfFileIcon} alt="PdfFileIcon" />
                  <span>PDF File</span>
                </div>
                <div
                  className="box"
                  onClick={() => handleFileUpload("docFile")}
                >
                  <img src={DocIcon} alt="DocIcon" />
                  <span>Doc File</span>
                </div>
                {/* <div className="box">
                  <img src={DriveIcon} alt="DriveIcon" />
                  <span>Google Drive</span>
                </div>
                <div className="box">
                  <img src={AirtableIcon} alt="AirtableIcon" />
                  <span>Airtable</span>
                </div> */}
              </div>
            </div>
            {fileType !== "simpleText" ? (
              <div className="choose-height">
              <Chooseimage
                onChange={handleOnChange}
                value={fileUpload || {name:`Please upload ${fileTypes[fileType]}`}}
                onRemove={() => handleOnRemoveImage()}
              />
              <span style={{color:'red'}}>{errors}</span>
              </div>
            ) : (<>
              <Textarea placeholder="Input text" value={fileUpload} onChange={handleOnChange} />
              <span style={{color:'red'}}>{errors}</span>
            </>
            )}
            <div className="button-right-alignment">
              <Button text="Create" onClick={handleSubmit} loader={loading}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
