import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session"; //or session
import authSlice from "./slices/authSlice";
import subscriptionSlice from "./slices/planSlice";
import dashboardSlice from "./slices/dashboardSlice";
import projectSlice from "./slices/projectSlice";
import embeddingSlice from "./slices/embeddingSlice";

const appReducer = combineReducers({
  auth: authSlice.reducer,
  subscription: subscriptionSlice.reducer,
  dashboard: dashboardSlice.reducer,
  project:projectSlice.reducer,
  embedding:embeddingSlice.reducer
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth","project","subscription"],
};
const rootReducer = (state, action) => {
  if (action.type === "auth/logout") {
    storage.removeItem("persist:root");
    localStorage.removeItem("persist:root");
    localStorage.removeItem("persist:reducer");
    // localStorage.clear();
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      // {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
    }),
  // devTools: false,
});
export const persistor = persistStore(store);
export default store;
