import React, { useEffect } from 'react'
import './dashboard.scss';
import Myproject from './myproject';
import NoprojectSection from './noprojectsection';
import Myprojectcard from './myprojectcard';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProject } from '../../store/slices/projectSlice';
import { getSubscription } from '../../store/slices/planSlice';
import Upgradeplanmodal from '../../components/modal/upgradeplanmodal';
import Updatemodal from '../../components/modal/updatemodal';
import Contactus from '../../components/modal/contactus';

export default function Dashboard() {
  const {project}=useSelector((state)=>state.project)
  const dispatch=useDispatch()

  useEffect(()=>{
    dispatch(getAllProject())
    dispatch(getSubscription())
  },[])

  return (
    <div>
      {/* <Contactus/> */}
      {/* <Updatemodal/> */}
      <Myproject/>
     <Upgradeplanmodal/>
      {project?.length>0&&<Myprojectcard/>
        
      }
      {!project?.length>0&&<NoprojectSection/>}
      {/* <Changepassword/> */}
    </div>
  )
}
