import React, { useState } from "react";
import "./embeddingscript.scss";
import Textarea from "../../../components/textarea";
import CopyIcon from "../../../assets/icons/copy.svg";
import { useSelector } from "react-redux";

export default function Embeddingscript() {
  const { focusedProject } = useSelector((state) => state.project);
  const [copiedUrl, setCopiedUrl] = useState(false);
  const projectPecificScript = `<script 
  src="https://cdn.jsdelivr.net/gh/botify-ai-tech/aifactory-chatbot-js@main/chatbot.js" 
  id="${focusedProject?.api_key}">
</script>`;

  return (
    <div className="embeddingscript-all-contnet-alignment">
      <h4>Embedding Script </h4>
      <div
        className="box"
        onClick={() => {
          navigator.clipboard.writeText(projectPecificScript);
          setCopiedUrl(true);
          setTimeout(() => {
            setCopiedUrl(false);
          }, 500);
        }}
      >
        <div style={{ position: "absolute", right: "0px", top: "9px" ,zIndex:99,cursor:'pointer'}}>
          {copiedUrl ? (
            <p style={{ color: "white", fontSize: "10px" }}>Copied!</p>
          ) : (
            <img src={CopyIcon} alt="copyIcon"/>
          )}
        </div>
        <Textarea
          value={projectPecificScript}
          disabled={true}
          placeholder='<script 
     src="https://cdn.jsdelivr.net/gh/botify-ai-tech/aifactory-chatbot-js@main/chatbot.js" 
     id="2ce9c46d34a363194c34f1d7175e2bce">
</script>
'
        />
      </div>
    </div>
  );
}
